import React from "react";
import Button from "../_FormComponents/Button";
import Picture from "../Picture";
import { Link } from "react-router-dom";
import womanpic from "../../assets/images/o-nama.png";

const HomeLab = () => {
  return (
    <section className="homeLab main-padding">
      <Picture image={womanpic} />
      <div className="homeLab--content">
        <h1>MAK Lab</h1>
        <h3>Biohemijska laboratorija</h3>
        <p>
          Biohemijska laboratorija Mak Lab Plus osnovana je u junu 2016. godine sa ciljem da pruži usluge kvalitetne
          laboratorijske dijagnostike.
        </p>
        <p>Svakodnevnom kontrolom kvaliteta našim korisnicima usluga pružamo garanciju tačnosti izdatih rezultata.</p>
        <p>
          Radno vreme laboratorije je od <span className="number">07</span> do
          <span className="number">19</span> časova radnim danima, i ukoliko se uzorak uzme do{" "}
          <span className="number">17h</span>, većina standardnih biohemijskih i hematoloških analiza može biti gotova
          do
          <span className="number">19h</span>. Izuzetak je mikrobiologija čiji proces rada duže traje.
        </p>
        <Link to="./onama">
          <Button color="red" text="Saznajte više o nama" />
        </Link>
      </div>
    </section>
  );
};

export default HomeLab;
