import React, { Component } from "react";
import InlineSVG from "svg-inline-react";
import { triangle, bullet } from "../../assets/svg";
import MenuIcon from "../_FormComponents/MenuIcon";
import preporuke from "../../config/preporuke";
import ReactPixel from "react-facebook-pixel";

class HomePreporuke extends Component {
  state = {
    activeKey: "Vadjenje Krvi"
  };

  handleClick = activeKey => {
    ReactPixel.trackCustom("Klik na preporuku (Desktop)", { preporuka: activeKey });
    this.setState({ activeKey });
  };

  render() {
    let { activeKey } = this.state;
    return (
      <section className="home-preporuke main-padding">
        <div className="home-preporuke--left">
          <h2>Preporuke i Saveti</h2>
          <p className="home-preporuke--txt">
            Da bi Vaši rezultati bili tačni i pouzdani potrebno je da se pridržavate sledećih preporuka:
          </p>
          <div className="home-preporuke--content">
            <div className="home-preporuke--nav">
              {Object.keys(preporuke).map(key => {
                const { title, icon } = preporuke[key];
                return (
                  <div
                    key={title}
                    className={`icon--container ${activeKey === key && "active"}`}
                    onClick={() => this.handleClick(key)}
                  >
                    <MenuIcon icon={icon} />
                    <div className="icon-text">{title}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="home-preporuke--right">
          <div className="home-preporuke--side-text">
            <MenuIcon icon={preporuke[activeKey].icon} />
            <h3>{preporuke[activeKey].title}</h3>
            {preporuke[activeKey].items.map(item => (
              <p key={item.text}>
                <InlineSVG src={bullet} />
                {item.text}
              </p>
            ))}
          </div>
          <div className="home-preporuke--triangle">
            <InlineSVG src={triangle} />
          </div>
        </div>
      </section>
    );
  }
}

export default HomePreporuke;
