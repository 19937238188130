export const virusologija = [
  {
    "name": "West Nile Virus IgM At",
    "price": "1800,00"
  },
  {
    "name": "West Nile Virus IgG At",
    "price": "1800,00"
  },
  {
    "name": "Aviditet Rubella IgG",
    "price": "2650,00"
  },
  {
    "name": "S-HSV 1 IgG",
    "price": "1350,00"
  },
  {
    "name": "HSV 1 IgM",
    "price": "1350,00"
  },
  {
    "name": "CMV IgG",
    "price": "1350,00"
  },
  {
    "name": "CMV IgM",
    "price": "1350,00"
  },
  {
    "name": "Morbilli virus IgM",
    "price": "1500,00"
  },
  {
    "name": "Morbilli virusi IgG",
    "price": "1500,00"
  },
  {
    "name": "Rubella IgM",
    "price": "1300,00"
  },
  {
    "name": "Rubella IgG",
    "price": "1300,00"
  },
  {
    "name": "Mumps virus IgM",
    "price": "1450,00"
  },
  {
    "name": "Mumps virus IgG",
    "price": "1450,00"
  },
  {
    "name": "Ebstein Barr IgG",
    "price": "1350,00"
  },
  {
    "name": "Ebstein Barr IgM",
    "price": "1350,00"
  },
  {
    "name": "Paul Bunell",
    "price": "1600,00"
  },
  {
    "name": "Coxsakie virus IgM",
    "price": "1350,00"
  },
  {
    "name": "Coxsakie virus IgG",
    "price": "1350,00"
  },
  {
    "name": "HBs Ag",
    "price": "1300,00"
  },
  {
    "name": "Hbs At",
    "price": "1300,00"
  },
  {
    "name": "Hbc IgM At",
    "price": "1350,00"
  },
  {
    "name": "Hbc ukupna At",
    "price": "1350,00"
  },
  {
    "name": "Anti HIV 1+2 At (Elisa)",
    "price": "1300,00"
  },
  {
    "name": "HIV 1+ 2 Ag/At",
    "price": "1450,00"
  },
  {
    "name": "HCV ukupna At",
    "price": "1350,00"
  },
  {
    "name": "HAV IgM",
    "price": "1350,00"
  },
  {
    "name": "Adenovirus IgM",
    "price": "1350,00"
  },
  {
    "name": "Adenovirus IgG",
    "price": "1350,00"
  },
  {
    "name": "HEV IgM antitela",
    "price": "1500,00"
  },
  {
    "name": "VZV IgG",
    "price": "1350,00"
  },
  {
    "name": "VZV IgM",
    "price": "1350,00"
  },
  {
    "name": "HSV 2 IgG",
    "price": "1650,00"
  },
  {
    "name": "HSV 2 IgM",
    "price": "1650,00"
  },
  {
    "name": "RSV IgG ( Elisa )",
    "price": "1450,00"
  },
  {
    "name": "RSV IgM ( Elisa )",
    "price": "1450,00"
  },
  {
    "name": "Infl. virus A IgM",
    "price": "1450,00"
  },
  {
    "name": "Infl. virus A IgG",
    "price": "1450,00"
  },
  {
    "name": "Infl. virus B IgM",
    "price": "1450,00"
  },
  {
    "name": "Infl. virus B IgG",
    "price": "1450,00"
  },
  {
    "name": "Parvo B 19 IgM",
    "price": "1450,00"
  },
  {
    "name": "Parvo B 19 Ig G",
    "price": "1450,00"
  },
  {
    "name": "Hbe Ag",
    "price": "1400,00"
  },
  {
    "name": "Hbe At",
    "price": "1400,00"
  },
  {
    "name": "Echo virus IgM",
    "price": "1500,00"
  },
  {
    "name": "Echo virus IgG",
    "price": "1500,00"
  },
  {
    "name": "Smt-TORCH TEST",
    "price": "11200,00"
  },
  {
    "name": "Parainfluenza 1 IgM",
    "price": "1500,00"
  },
  {
    "name": "Parainfluenza 3 IgM",
    "price": "1500,00"
  },
  {
    "name": "HIV Western Blot",
    "price": "5000,00"
  },
  {
    "name": "HAV uk At",
    "price": "1400,00"
  },
  {
    "name": "K-HCV RNK kvalitativno",
    "price": "11000,00"
  },
  {
    "name": "K-HCV RNK kvantitativno",
    "price": "11000,00"
  },
  {
    "name": "HCV WB",
    "price": "9500,00"
  },
  {
    "name": "Krpelj-analiza",
    "price": "5000,00"
  },
  {
    "name": "Toxoplasma gondii IgG",
    "price": "1300,00"
  },
  {
    "name": "Toxoplasma gondii IgM",
    "price": "1300,00"
  },
  {
    "name": "Toxoplasma gondii aviditet",
    "price": "2800,00"
  },
  {
    "name": "WNV IgM",
    "price": "1800,00"
  },
  {
    "name": "SARS-CoV-2 IgM At",
    "price": "2200,00"
  },
  {
    "name": "SARS-CoV-2 IgG At",
    "price": "2200,00"
  },
  {
    "name": "Coxiella Burneti At",
    "price": "2800,00"
  },
  {
    "name": "Aspergilus fumigatus IgA",
    "price": "2000,00"
  },
  {
    "name": "HEV ukupna antitela",
    "price": "1600,00"
  }
]