import React from "react";
import Picture from "../../components/Picture";
import Helmet from "react-helmet";
import BackgroundImage from "../../components/BackgroundImage";
import womanpic from "../../assets/images/o-nama.png";

const About = props => {
  return (
    <>
      <Helmet>
        <title>{props.metaTags.title}</title>
        <meta
          name="description"
          content="Stručan kadar sa bogatim kliničkim i radnim iskustvom, kao i savremena oprema, dobra organizacija rada omogućavaju kvalitetnu i brzu obradu biološkog materijala."
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Uon Solutions" />
      </Helmet>
      <section className="about main-padding">
        <div className="about--text">
          <h1>MAK Lab</h1>
          <h3>Biohemijska Laboratorija</h3>
          <BackgroundImage image="onama" />
          <p>
            Biohemijska laboratorija Mak Lab Plus osnovana je u junu 2016. godine sa ciljem da pruži usluge kvalitetne
            laboratorijske dijagnostike. Stručan kadar sa bogatim kliničkim i radnim iskustvom, kao i savremena oprema,
            dobra organizacija rada omogućavaju kvalitetnu i brzu obradu biološkog materijala.
          </p>

          <p>Svakodnevnom kontrolom kvaliteta našim korisnicima usluga pružamo garanciju tačnosti izdatih rezultata.</p>
          <p>
            Radno vreme laboratorije je od <span className="number">07</span> do
            <span className="number">19</span> časova radnim danima, i ukoliko se uzorak uzme do{" "}
            <span className="number">17h</span>, većina standardnih biohemijskih i hematoloških analiza može biti gotova
            do
            <span className="number">19h</span>. Izuzetak je mikrobiologija čiji proces rada duže traje.
          </p>
          <p>
            Takođe posedujemo terensku službu za šire područje Beograda za osobe koje nisu u mogućnosti da dođu do nas.
          </p>
          <p>Nakon godinu dana uspešnog rada otvorena je i mirobiološka laboratorija u naselju Braće Jerković.</p>
        </div>
        <Picture image={womanpic} />
      </section>
    </>
  );
};

export default About;
