export const molDijagnostika = [
  {
    "name": "HBV - DNA kvantitativno",
    "price": "12000,00"
  },
  {
    "name": "HBV genotipizacija",
    "price": "12000,00"
  },
  {
    "name": "HBV rezistencija (lamividin)",
    "price": "13000,00"
  },
  {
    "name": "HCV -RNA kvalitativno",
    "price": "11000,00"
  },
  {
    "name": "HCV -RNA kvantitativno",
    "price": "11000,00"
  },
  {
    "name": "HCV- genotipizacija",
    "price": "14000,00"
  },
  {
    "name": "HPV - genotipizacija",
    "price": "9000,00"
  },
  {
    "name": "TBC-PCR",
    "price": "7000,00"
  },
  {
    "name": "TBC-PCR urin",
    "price": "7000,00"
  },
  {
    "name": "HIV- PCR",
    "price": "18000,00"
  },
  {
    "name": "Chlamidia trachomatis PCR ( bris, urin)",
    "price": "5500,00"
  },
  {
    "name": "HBV-DNA kvalitativno",
    "price": "11000,00"
  },
  {
    "name": "EBV kvantitativno PCR",
    "price": "5500,00"
  },
  {
    "name": "STD3(Chlamydia, Ureaplasma, Micoplasma)",
    "price": "7000,00"
  }
]