import React from "react";
// import Maps from "../../components/Maps";
import Helmet from "react-helmet";

const Location = props => {
  return (
    <>
      <Helmet>
        <title>{props.metaTags.title}</title>
        <meta
          name="description"
          content="Laboratorija MakLab osnovana je sa ciljem da pruži usluge kvalitetne laboratorijske dijagnostike."
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Uon Solutions" />
      </Helmet>
      <div className="maps--container main-padding">
        <div className="maps--box">
          <iframe
            title="map"
            // width="640"
            // height="500"
            id="gmap_canvas"
            src="https://maps.google.com/maps?q=Me%C5%A1tovi%C4%87eva%2030b%2C%20Vo%C5%BEdovac%2C%20Beograd&t=&z=11&ie=UTF8&iwloc=&output=embed"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
          />
          <div className="text">
            <h3>Lokacija No.1</h3>
            <p>
              Adresa:{" "}
              <span className="number">Meštovićeva 30b, Voždovac, Beograd</span>
            </p>
            <p>
              Do nas možete doći autobuskim linijama
              <span className="number"> 25, 25p i 18</span>
            </p>
          </div>
        </div>
        <div className="maps--box">
          <iframe
            title="map"
            // width="640"
            // height="500"
            id="gmap_canvas"
            src="https://maps.google.com/maps?q=%C5%A0tefaneka%2018%20Beograd&t=&z=11&ie=UTF8&iwloc=&output=embed"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
          />
          <div className="text">
            <h3>Lokacija No.2</h3>
            <p>
              Adresa:{" "}
              <span className="number">
                Generala Štefanika 18/lok 2, Voždovac, Beograd
              </span>
            </p>
            <p>
              Do nas možete doći autobuskim linijama
              <span className="number"> 25, 25p, 39, 50 i 49</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Location;
