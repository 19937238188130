export const vitamini = [
  {
    "name": "Vitamin A",
    "price": "2500,00"
  },
  {
    "name": "Vitamin E",
    "price": "2500,00"
  },
  {
    "name": "Vitamin B6",
    "price": "2500,00"
  },
  {
    "name": "Vitamin H",
    "price": "3000,00"
  },
  {
    "name": "Vitamin C",
    "price": "2000,00"
  },
  {
    "name": "Chlamidia trachomatis PCR ( bris, urin)",
    "price": "5500,00"
  },
  {
    "name": "HBV-DNA kvalitativno",
    "price": "11000,00"
  },
  {
    "name": "EBV kvantitativno PCR",
    "price": "5500,00"
  },
  {
    "name": "STD3(Chlamydia, Ureaplasma, Micoplasma)",
    "price": "7000,00"
  }
]