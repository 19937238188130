export const feces = [
  {
    "name": "Feces -svarljivost",
    "price": "900,00"
  },
  {
    "name": "Fekal kalprotektin",
    "price": "3200,00"
  },
  {
    "name": "F-FECES test na okultno krvarenje",
    "price": "700,00"
  },
  {
    "name": "K-Pankreasna elastaza",
    "price": "4000,00"
  }
]