import React, { Component } from "react";
import Button from "../../_FormComponents/Button";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import ReactPixel from "react-facebook-pixel";

const ContactSchema = Yup.object().shape({
  name: Yup.string()
    .required("Molimo Vas unesite ime.")
    .min(4, "Ime je prekratko.")
    .max(50, "Maksimalna dužina imena je 50 karaktera."),
  email: Yup.string()
    .required("Email je neophodan.")
    .email("Email je neispravan."),
  message: Yup.string()
    .required("Molimo Vas unesite poruku.")
    .max(1000, "Maksimalna dužina poruke je 1000 karaktera.")
});

class ContactForm extends Component {
  state = {
    response: null
  };

  handleSubmit = async (values, { setSubmitting }) => {
    ReactPixel.track("Contact", values);
    try {
      let response = await axios.post("https://mailer.uon.rs/send", {
        hostname: "maklab.rs",
        fullName: values.name,
        email: values.email,
        message: values.message
      });
      this.setState({ response: response.data.message });
    } catch (err) {
      console.log(err);
    }
    setSubmitting(false);
  };

  render() {
    return (
      <Formik
        initialValues={{ name: "", email: "", message: "" }}
        validationSchema={ContactSchema}
        onSubmit={this.handleSubmit}
      >
        {({ values, errors, handleChange, handleSubmit, isSubmitting }) => {
          const { response } = this.state;
          return (
            <form className="contact--form">
              <div className="form--wrapper">
                <div className="form--row">
                  <input
                    className={errors.name ? "error" : ""}
                    type="text"
                    name="name"
                    placeholder="Ime i prezime"
                    value={values.name}
                    onChange={handleChange}
                  />
                  {/* Error handling for name: */}
                  {/* {touched.name && errors.name ? <p>{errors.name}</p> : null} */}
                  <input
                    className={errors.email ? "error" : ""}
                    type="text"
                    name="email"
                    placeholder="Email"
                    value={values.email}
                    onChange={handleChange}
                  />
                  {/* Error handling for email: */}
                  {/* {touched.email && errors.email ? <p>{errors.email}</p> : null} */}
                </div>
                <div className="form--row">
                  <textarea
                    className={errors.message ? "error" : ""}
                    name="message"
                    placeholder="Poruka"
                    value={values.message}
                    onChange={handleChange}
                  />
                  {/* Error handling for message: */}
                  {/* {touched.message && errors.message ? (
                    <p>{errors.message}</p>
                  ) : null} */}
                </div>
              </div>
              <div className="buttons">
                <div className="message">
                  {response !== null ? <span className="success">{response}</span> : null}
                  {Object.keys(errors).length === 0 ? null : errors[Object.keys(errors)[0]]}
                  {/* {touched.name && errors.name ? errors.name : null} */}
                </div>
                <Button disabled={isSubmitting} onClick={handleSubmit} color="red" text="Pošalji" />
              </div>
            </form>
          );
        }}
      </Formik>
    );
  }
}

export default ContactForm;
