export const hormoni = [
  {
    "name": "S-T3",
    "price": "600,00"
  },
  {
    "name": "S-T4",
    "price": "600,00"
  },
  {
    "name": "S-hsTSH",
    "price": "600,00"
  },
  {
    "name": "S-TSH",
    "price": "600,00"
  },
  {
    "name": "S-FT3",
    "price": "650,00"
  },
  {
    "name": "S-FT4",
    "price": "650,00"
  },
  {
    "name": "S-TG",
    "price": "1200,00"
  },
  {
    "name": "S-Anti Tg",
    "price": "1100,00"
  },
  {
    "name": "S-Anti TPO",
    "price": "1100,00"
  },
  {
    "name": "S-Anti TSH receptori",
    "price": "1950,00"
  },
  {
    "name": "S-TBG",
    "price": "1350,00"
  },
  {
    "name": "S-Kalcitonin",
    "price": "1500,00"
  },
  {
    "name": "S-PTH intaktni",
    "price": "1050,00"
  },
  {
    "name": "S-Kortizol",
    "price": "950,00"
  },
  {
    "name": "P-ACTH",
    "price": "1050,00"
  },
  {
    "name": "S-Estradiol",
    "price": "770,00"
  },
  {
    "name": "S-Prolaktin",
    "price": "770,00"
  },
  {
    "name": "S-Testosteron",
    "price": "770,00"
  },
  {
    "name": "S-Testosteron free",
    "price": "1450,00"
  },
  {
    "name": "S-SHBG",
    "price": "1000,00"
  },
  {
    "name": "S-Androstendion",
    "price": "1300,00"
  },
  {
    "name": "S-DHEA- SO4",
    "price": "1050,00"
  },
  {
    "name": "S-DHEA",
    "price": "1450,00"
  },
  {
    "name": "S-IGF1",
    "price": "1700,00"
  },
  {
    "name": "S-Hormon rasta",
    "price": "1050,00"
  },
  {
    "name": "S-Estriol free",
    "price": "1050,00"
  },
  {
    "name": "S-Free beta HCG",
    "price": "1350,00"
  },
  {
    "name": "S-PAPP-A",
    "price": "1400,00"
  },
  {
    "name": "S-Insulin",
    "price": "1100,00"
  },
  {
    "name": "S-HOMA index",
    "price": "1,00"
  },
  {
    "name": "S-Insulinska antitela",
    "price": "1350,00"
  },
  {
    "name": "S-C-peptid",
    "price": "1100,00"
  },
  {
    "name": "S-17 OH progesteron",
    "price": "1350,00"
  },
  {
    "name": "S-Inhibin B",
    "price": "2100,00"
  },
  {
    "name": "Inhibin A",
    "price": "2900,00"
  },
  {
    "name": "P-Antidiuretski hormon ( ADH )",
    "price": "2600,00"
  },
  {
    "name": "S-Anti-Mullerian hormon",
    "price": "2650,00"
  },
  {
    "name": "DOUBLE TEST I (fbeta HCG + PAPP-A)",
    "price": "2800,00"
  },
  {
    "name": "DOUBLE TEST II (f beta HCG + AFP)",
    "price": "2300,00"
  },
  {
    "name": "TRIPLE TEST",
    "price": "3000,00"
  },
  {
    "name": "QUADRIPLE TEST",
    "price": "6000,00"
  },
  {
    "name": "S-Gastrin",
    "price": "1400,00"
  },
  {
    "name": "P-Kateholamini - plazma",
    "price": "3900,00"
  },
  {
    "name": "S-Free METANEFRIN u plazmi",
    "price": "2800,00"
  },
  {
    "name": "NORMETANEFRIN u plazmi",
    "price": "3200,00"
  },
  {
    "name": "S-IGFBP3",
    "price": "1700,00"
  },
  {
    "name": "S-Dihidrotestosteron",
    "price": "2000,00"
  },
  {
    "name": "Renin ( PRA )",
    "price": "1800,00"
  },
  {
    "name": "Serotonin",
    "price": "3600,00"
  },
  {
    "name": "Leptin",
    "price": "1350,00"
  },
  {
    "name": "K-Oksitocin",
    "price": "3500,00"
  },
  {
    "name": "S-Adiponektin",
    "price": "3500,00"
  },
  {
    "name": "S-Makroprolaktin",
    "price": "2200,00"
  },
  {
    "name": "K-HPL Humani Placentalni Laktogen",
    "price": "1900,00"
  },
  {
    "name": "Anti-Mullerian hormon (hitno)",
    "price": "3000,00"
  },
  {
    "name": "ADRENALIN,NORADRENALIN,DOPAMIN",
    "price": "4500,00"
  }
]