import React from "react";

const Button = props => {
  const { color, text } = props;
  return (
    <div className={`button ${color}`} {...props}>
      {text}
    </div>
  );
};

export default Button;
