import React, { Component } from "react";
import logo from "../../logo.png";
import { prices } from "../../config";
import Accordion from "../Accordion/Accordion";
import BackgroundImage from "../BackgroundImage";
import { bullet } from "../../assets/svg";
import ReactPixel from "react-facebook-pixel";

export default class HomeUsluge extends Component {
  state = {
    active: null
  };

  setActive = active => {
    if (this.state.active === active) {
      this.setState({ active: null });
    } else {
      ReactPixel.trackCustom("Klik na uslugu", { usluge: active });
      this.setState({ active });
    }
  };

  render() {
    let cene = Object.keys(prices).map((kategorija, i) => {
      return (
        <li
          key={i}
          className={`icon--container ${this.state.active === kategorija ? "active" : " "}`}
          onClick={() => this.setActive(kategorija)}
        >
          <Accordion activeName={this.state.active} name={kategorija} icon={bullet} prices={prices[kategorija]} />
        </li>
      );
    });

    return (
      <section className="homeUslugeResponsive main-padding" style={{ marginTop: '150px' }}>
        <BackgroundImage image="onama" />
        <h2 id="homeUsluge--naslov">Naše Usluge</h2>
        <h3 id="homeUsluge--small">Cenovnik</h3>
        <div className="homeUsluge--content">
          <BackgroundImage image="contact" id="usluge" />
          <ul className="homeUsluge--list">{cene}</ul>
          <div className="homeUsluge--info">
            <h3>Izdavanje rezultata</h3>
            <p>
              Izdavanje rezulata za većinu biohemijskih i hematoloških analiza, za hormone, tumor markere, urine i
              spermograme je 1-3 sata posle davanja uzorka.
            </p>
            <h3>Niste sigurni koja Vam je analiza potrebna?</h3>
            <p>
              Pozovite nas na{" "}
              <span className="number">
                <a href="tel:+381116306713">011/630 67 13</a>
              </span>{" "}
              Naše osoblje će Vam pomoći oko svih dodatnih informacija.
            </p>
            <img alt="logo" id="homeUsluge--logo" src={logo} />
          </div>
        </div>
      </section>
    );
  }
}
