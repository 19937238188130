export const koncentracijaLekova = [
  {
    "name": "S-Karbamazepin",
    "price": "1200,00"
  },
  {
    "name": "S-Valproinska kiselina",
    "price": "1200,00"
  },
  {
    "name": "S-Litijum",
    "price": "480,00"
  },
  {
    "name": "S-Fenobarbiton",
    "price": "1200,00"
  },
  {
    "name": "S-Lamictal ( Lamotrigin )",
    "price": "3000,00"
  },
  {
    "name": "S-Digoksin",
    "price": "1900,00"
  }
]