export const koagulacija = [
  {
    "name": "P-Von Willebrand-ov faktor",
    "price": "2600,00"
  },
  {
    "name": "K-Vreme koagulacije",
    "price": "150,00"
  },
  {
    "name": "P-Protrombinsko vreme",
    "price": "310,00"
  },
  {
    "name": "P-aPTT",
    "price": "310,00"
  },
  {
    "name": "P-Fibrinogen",
    "price": "280,00"
  },
  {
    "name": "K-Vreme krvarenja",
    "price": "150,00"
  },
  {
    "name": "P-Trombinsko vreme ( TT )",
    "price": "350,00"
  },
  {
    "name": "P-D-dimer",
    "price": "1850,00"
  },
  {
    "name": "Antitrombin III",
    "price": "2150,00"
  },
  {
    "name": "Protein C",
    "price": "2800,00"
  },
  {
    "name": "Protein S",
    "price": "2800,00"
  },
  {
    "name": "APCR (rezistencija na aktivisani protein C)",
    "price": "2200,00"
  },
  {
    "name": "HEPARIN-anti Xa aktivnost",
    "price": "1800,00"
  },
  {
    "name": "K-Koagulacioni faktor VIII",
    "price": "2750,00"
  },
  {
    "name": "Koagulacioni faktor II(protrombin)",
    "price": "2900,00"
  }
];