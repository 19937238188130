import { hematologija } from "./pricing/001hematologija";
import { koagulacija } from "./pricing/002koagulacija";
import { biohemija } from "./pricing/003biohemija";
import { alergijaINetolerancijaNaHranu } from "./pricing/004alergijaINetolerancijaNaHranu";
import { urin } from "./pricing/005urin";
import { testNaDroge } from "./pricing/006testNaDroge";
import { urin24 } from "./pricing/007urin24";
import { hormoni } from "./pricing/008hormoni";
import { imunohemija } from "./pricing/009imunohemija";
import { tumorMarkeri } from "./pricing/010tumorMarkeri";
import { kostaniMarkeri } from "./pricing/011kostaniMarkeri";
import { mikrobiologija } from "./pricing/012mikrobiologija";
import { virusologija } from "./pricing/013virusologija";
import { spermogram } from "./pricing/014spermogram";
import { koncentracijaLekova } from "./pricing/015koncentracijaLekova";
import { alergijaNaLekove } from "./pricing/016alergijaNaLekove";
import { feces } from "./pricing/017feces";
import { genetika } from "./pricing/018genetika";
import { molDijagnostika } from "./pricing/019molekularnaDijagnostika";
import { vitamini } from "./pricing/020vitamini";
import { saliva } from "./pricing/021saliva";
import { ostalo } from "./pricing/022ostalo";
import meta from "./meta";
// 1472708216212960
export const FACEBOOK_PIXEL_ID = "593701597816400";

export const prices = {
  Hematologija: hematologija,
  Koagulacija: koagulacija,
  Biohemija: biohemija,
  "Alergija I Netolerancija Na Hranu": alergijaINetolerancijaNaHranu,
  Urin: urin,
  "Test na droge (Urin)": testNaDroge,
  "Urin - 24H": urin24,
  Hormoni: hormoni,
  Imunohemija: imunohemija,
  "Tumor markeri": tumorMarkeri,
  "Koštani markeri": kostaniMarkeri,
  Mikrobiologija: mikrobiologija,
  Virusologija: virusologija,
  Spermogram: spermogram,
  "Koncentracija lekova": koncentracijaLekova,
  "Alergija na lekove": alergijaNaLekove,
  Feces: feces,
  "Genetika i molekularna dijagnostika": genetika,
  "Molekularna dijagnostika PCR": molDijagnostika,
  Vitamini: vitamini,
  Saliva: saliva,
  Ostalo: ostalo
};

export { meta };
