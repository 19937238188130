import React from "react";
import InlineSVG from "svg-inline-react";

const icons = {
  krv: `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 461.727 461.727"><path d="M236.436 6.861L230.868 0 225.3 6.861c-6.576 8.104-160.824 199.02-160.824 288.475 0 91.739 74.645 166.392 166.384 166.392s166.392-74.653 166.392-166.392c.008-89.48-154.256-280.379-160.816-288.475zm-53.982 388.147l-2.162-1.024c-53.258-25.353-70.987-93.861-54.185-141.949l.797-2.243 13.542 4.715-.788 2.268c-14.477 41.35 1.187 102.542 46.796 124.286l2.162 1.024-6.162 12.923z"/></svg>`,
  secer: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 430.56 430.56">
        <path d="M383.281 87.992L373.047 57.52l-30.48-10.239a7.997 7.997 0 0 1-4.31-3.48L312.079 0l-44.75 89.55a136.967 136.967 0 0 1 73.68 73.688l89.55-44.758-43.78-26.183a8.002 8.002 0 0 1-3.497-4.305zm-336 254.574l10.246 30.473 30.465 10.242a8.005 8.005 0 0 1 4.313 3.48l26.175 43.798 44.743-89.551a136.954 136.954 0 0 1-73.68-73.688L0 312.078l43.785 26.188a7.997 7.997 0 0 1 3.496 4.3zm0 0"/><path d="M300.129 300.129c40.476-40.484 46.723-103.926 14.914-151.524-31.809-47.597-92.813-66.101-145.703-44.187-52.89 21.91-82.934 78.133-71.762 134.281 11.172 56.149 60.453 96.586 117.703 96.582a119.214 119.214 0 0 0 84.848-35.152zM141.727 153.055c-3.122-3.121-3.122-8.188 0-11.313 40.632-40.566 106.441-40.566 147.074 0a8.002 8.002 0 0 1-3.531 13.496 8.001 8.001 0 0 1-7.782-2.183c-34.386-34.313-90.062-34.313-124.449 0a8 8 0 0 1-11.297 0zm0 0"/>
    </svg>`,
  urin: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 445 521.9">
        <path d="M64.3 0L445 117.3l-14.7 47.8L49.6 47.8 64.3 0zM258.1 170H0l20 351.9h340.2l20-351.9z"/>
    </svg>`,
  bris: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path d="M492.2 402.1l-71.7-44.8-101.1-101.1 101.1-101.1 71.7-44.8 1.2-1.2c24.9-24.9 24.9-65.4 0-90.2C468.5-6 428-6 403.1 18.9l-1.2 1.2-44.8 71.7-101.1 101-101-101-9.1-14.6-35.7-57L109 19C96.9 6.8 80.9.2 63.8.2 55.3.2 47 1.9 39.4 5c-7.6 3.2-14.7 7.8-20.7 13.9C6.7 30.9 0 47 0 64s6.6 33.1 18.7 45.1l1.2 1.2 57.6 36 14 8.8 101.1 101.1-101 101.1-71.7 44.8-1.2 1.2c-24.9 24.9-24.9 65.4 0 90.2 12.4 12.5 28.8 18.7 45.1 18.7s32.7-6.2 45.1-18.7l1.2-1.2 44.8-71.7 101.1-101 101.1 101.1 44.8 71.7 1.2 1.2c12.4 12.4 28.8 18.7 45.1 18.7 8.2 0 16.3-1.6 24-4.7 7.7-3.1 14.9-7.8 21.1-14 24.9-24.9 24.9-65.4 0-90.2l-1.1-1.3zM425.4 39c6.2-5.7 14.3-8.8 22.8-8.8 4.5 0 8.9.9 12.9 2.6 4.1 1.7 7.8 4.2 11 7.4C478.5 46.5 482 55 482 64c0 8.5-3.1 16.6-8.8 22.8l-59.9 37.5L387.9 99l37.5-60zM98.7 124.3L38.8 86.8C33.1 80.6 30 72.5 30 64c0-4.5.9-8.9 2.6-12.9 1.7-4.1 4.2-7.8 7.4-11 6.4-6.4 14.9-9.9 23.9-9.9 8.5 0 16.6 3.1 22.8 8.8l37.4 60-25.4 25.3zM86.6 473.4c-6.2 5.7-14.3 8.8-22.8 8.8-4.5 0-8.9-.9-12.9-2.6-4.1-1.7-7.8-4.2-11-7.4-6.4-6.4-9.9-14.9-9.9-23.9 0-8.5 3.1-16.6 8.8-22.8L98.7 388l25.3 25.3-37.4 60.1zm392.9-12c-1.7 4.1-4.2 7.8-7.4 11-6.4 6.4-14.9 9.9-23.9 9.9-8.5 0-16.6-3.1-22.8-8.8l-37.5-59.9 25.3-25.3 59.9 37.5c5.7 6.2 8.8 14.3 8.8 22.8.1 4.3-.8 8.7-2.4 12.8z"/><path d="M63.8 30.2c-9 0-17.5 3.5-23.9 9.9-3.2 3.2-5.7 6.9-7.4 11-1.7 4-2.6 8.4-2.6 12.9 0 8.5 3.1 16.6 8.8 22.8l59.9 37.5L124.1 99 86.6 39c-6.2-5.7-14.3-8.8-22.8-8.8zM473.2 86.8c5.7-6.2 8.8-14.3 8.8-22.8 0-9-3.5-17.5-9.9-23.9-3.2-3.2-6.9-5.7-11-7.4-4-1.7-8.4-2.6-12.9-2.6-8.5 0-16.6 3.1-22.8 8.8L387.9 99l25.3 25.3 60-37.5zM413.2 388.1l-25.3 25.3 37.5 59.9c6.2 5.7 14.3 8.8 22.8 8.8 9 0 17.5-3.5 23.9-9.9 3.2-3.2 5.7-6.9 7.4-11 1.7-4 2.6-8.4 2.6-12.9 0-8.5-3.1-16.6-8.8-22.8l-60.1-37.4zM38.8 425.6c-5.7 6.2-8.8 14.3-8.8 22.8 0 9 3.5 17.5 9.9 23.9 3.2 3.2 6.9 5.7 11 7.4 4 1.7 8.4 2.6 12.9 2.6 8.5 0 16.6-3.1 22.8-8.8l37.5-59.9-25.3-25.3-60 37.3z" class="st1"/>
    </svg>`,
  spermogram: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 494.1 494.1">
        <path d="M472.4 16.6c-30.8-30.8-93.9-17.6-141 29.5-38 38-53.9 86.5-42.4 120-47.6 60.5-19 86.5-41.7 118.3-14.9 20.9-58.7 12.8-75.5 13.5-46.1 2-66.7 30.7-69.6 71.7-4 56.9-23.3 59.9-91.9 103.2-12.3 8-1.1 26.8 11.7 19.7 97.3-54.3 93.8-47.9 114.4-137.4 1.7-7.3 7.9-14.3 14.9-17.2 35.8-15 79.7 21.5 127.1-25.9 9.5-8.6 20-28.5 22.5-51 2.9-25.2 1.8-37.2 22.7-60.9 33.4 11.1 81.6-4.8 119.3-42.5 47.1-47.1 60.3-110.2 29.5-141zM227.1 55.6c-14.6-14.6-44.6-8.4-67 14-18.1 18.1-25.6 41.1-20.1 57-22.6 28.7-9 41.1-19.8 56.2-7.1 9.9-27.9 6.1-35.9 6.4-21.9 1-31.7 14.6-33.1 34.1-1.9 27-11.1 28.5-43.6 49-5.9 3.7-.6 12.7 5.5 9.3 46.2-25.8 44.6-22.8 54.3-65.3.8-3.5 3.8-6.8 7.1-8.2 17-7.1 37.9 10.2 60.4-12.3 4.5-4.1 9.5-13.5 10.7-24.2 1.4-12 .8-17.7 10.8-28.9 15.9 5.3 38.7-2.3 56.7-20.2 22.3-22.3 28.6-52.3 14-66.9zM469.1 254.9c-14.6-14.6-44.6-8.4-67 14-18.1 18.1-25.6 41.1-20.1 57-22.6 28.7-9 41.1-19.8 56.2-7.1 9.9-27.9 6.1-35.9 6.4-21.9 1-31.7 14.6-33.1 34.1-1.9 27-11.1 28.5-43.6 49-5.9 3.7-.6 12.7 5.5 9.3 46.2-25.8 44.6-22.8 54.3-65.3.8-3.5 3.8-6.8 7.1-8.2 17-7.1 37.9 10.2 60.4-12.3 4.5-4.1 9.5-13.5 10.7-24.2 1.4-12 .8-17.7 10.8-28.9 15.9 5.3 38.7-2.3 56.7-20.2 22.3-22.3 28.6-52.2 14-66.9z"/>
    </svg>`,
  koprokultura: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M426.667 341.333H85.333C38.4 341.333 0 379.733 0 426.667S38.4 512 85.333 512h341.333C473.6 512 512 473.6 512 426.667s-38.4-85.334-85.333-85.334zm-87.467-128H170.667c-46.933 0-85.333 38.4-85.333 85.333 0 6.4 2.133 14.933 2.133 21.333H422.4c2.133-6.4 2.133-14.933 2.133-21.333 0-46.933-38.4-85.333-85.333-85.333zm4.267-66.133C326.4 81.067 247.467 46.933 243.2 44.8c-17.067-6.4-36.267 12.8-27.733 29.867C224 96 215.467 121.6 198.4 134.4c-17.067 14.933-25.6 36.267-25.6 57.6h170.667c4.266-14.933 4.266-29.867 0-44.8z"/>
    </svg>`,
  krvarenje: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500.251 500.251">
        <path d="M469.692 229.36l-63.611-64.696-63.631 64.696c-34.795 35.41-34.795 93.02 0 128.43 16.978 17.244 39.588 26.767 63.631 26.767 24.023 0 46.633-9.523 63.611-26.767 34.796-35.41 34.796-93.02 0-128.43zm-205.4 136.091l-39.096 39.793c-21.299 21.647-21.32 56.893 0 78.561 10.424 10.609 24.289 16.445 39.096 16.445 14.807 0 28.672-5.837 39.096-16.445 21.32-21.668 21.299-56.914 0-78.561l-39.096-39.793zm-10.379-260.245L150.469 0 47.024 105.206c-56.75 57.733-56.75 151.675 0 209.408 1.249 1.29 2.621 2.396 3.912 3.604.942.983 1.987 1.823 2.97 2.744a148.116 148.116 0 0 0 8.11 6.779c.143.102.287.225.43.328 25.231 19.436 55.788 30.106 88.023 30.106 39.096 0 75.837-15.483 103.444-43.561 56.75-57.733 56.75-151.675 0-209.408zM102.812 299.581c-5.591 0-10.772-1.638-15.319-4.28-2.867-2.191-5.673-4.444-8.335-6.963-4.813-5.673-7.844-13.025-7.844-21.156 0-17.859 14.131-32.379 31.498-32.379s31.498 14.52 31.498 32.379c0 17.879-14.131 32.399-31.498 32.399z"/>
    </svg>`
};

const MenuIcon = props => {
  const { onClick, icon } = props;
  return (
    <div className="iconComponent" onClick={onClick}>
      <div className="icon">
        <InlineSVG src={icons[icon]} />
      </div>
    </div>
  );
};

export default MenuIcon;
