export const imunohemija = [
  {
    "name": "S-Neuronalna At(Hu,Yo,Ri)",
    "price": "5000,00"
  },
  {
    "name": "S-Haptoglobin",
    "price": "700,00"
  },
  {
    "name": "S-CRP (C reaktivni protein)",
    "price": "600,00"
  },
  {
    "name": "S-hsCRP",
    "price": "600,00"
  },
  {
    "name": "S-IgA",
    "price": "720,00"
  },
  {
    "name": "S-IgG",
    "price": "720,00"
  },
  {
    "name": "S-IgM",
    "price": "720,00"
  },
  {
    "name": "S-IgE",
    "price": "950,00"
  },
  {
    "name": "Ukupni komplement CH50",
    "price": "900,00"
  },
  {
    "name": "C1 esteraza inhibitor",
    "price": "1350,00"
  },
  {
    "name": "S-C3-komplement",
    "price": "720,00"
  },
  {
    "name": "S-C4-komplement",
    "price": "720,00"
  },
  {
    "name": "S-Ceruloplazmin",
    "price": "1100,00"
  },
  {
    "name": "S-Alfa 1 antitripsin genotip",
    "price": "10600,00"
  },
  {
    "name": "S-ASO kvantitativno",
    "price": "660,00"
  },
  {
    "name": "S-Anti-DNase B",
    "price": "3000,00"
  },
  {
    "name": "S-Waaler Rose latex",
    "price": "660,00"
  },
  {
    "name": "S-C1q imunokompleksi",
    "price": "1500,00"
  },
  {
    "name": "S-cANCA (anti- PR3)",
    "price": "1250,00"
  },
  {
    "name": "S-AMA (antimitohondrijalna ) M2 At",
    "price": "1250,00"
  },
  {
    "name": "S-ANA screen ( ELISA )",
    "price": "1300,00"
  },
  {
    "name": "S-Anti ds DNA At",
    "price": "1300,00"
  },
  {
    "name": "S-Antifosfolipidna At IgM",
    "price": "1450,00"
  },
  {
    "name": "S-Antifosfolipidna At IgG",
    "price": "1450,00"
  },
  {
    "name": "S-Antikardiolipinska At IgM",
    "price": "1400,00"
  },
  {
    "name": "S-Antikardiolipinska At IgG",
    "price": "1400,00"
  },
  {
    "name": "P-Lupus antikoagulans",
    "price": "1350,00"
  },
  {
    "name": "K-Lupus celije",
    "price": "700,00"
  },
  {
    "name": "Lupusna Antitela",
    "price": "1300,00"
  },
  {
    "name": "S-Antispermatozoidna At",
    "price": "1300,00"
  },
  {
    "name": "S-Antispermatoz. At u spermi",
    "price": "1300,00"
  },
  {
    "name": "S-Antigliadinska At IgA",
    "price": "1300,00"
  },
  {
    "name": "S-Antigliadinska At IgG",
    "price": "1300,00"
  },
  {
    "name": "S-Antiparijetalna At",
    "price": "1350,00"
  },
  {
    "name": "S-Antiovarijalna At",
    "price": "1600,00"
  },
  {
    "name": "S-pANCA (anti- MPO)",
    "price": "1250,00"
  },
  {
    "name": "S-ASMA",
    "price": "1500,00"
  },
  {
    "name": "S-Reumatoidni faktor kvant.",
    "price": "650,00"
  },
  {
    "name": "S-Anti LKM-1 IgG",
    "price": "1350,00"
  },
  {
    "name": "S-ANA profil ( ENA )",
    "price": "4700,00"
  },
  {
    "name": "S-Anti Ro/SSA antitela",
    "price": "1550,00"
  },
  {
    "name": "S-Anti La/SSB",
    "price": "1550,00"
  },
  {
    "name": "S-Anti Sm antitela",
    "price": "1550,00"
  },
  {
    "name": "Anti Jo 1 antitela",
    "price": "1550,00"
  },
  {
    "name": "S-Anti CCP At",
    "price": "1550,00"
  },
  {
    "name": "S-ANA ( HEP-2 )",
    "price": "1500,00"
  },
  {
    "name": "S-Krioglobulini",
    "price": "660,00"
  },
  {
    "name": "Beta-2-glikoprotein I IgM",
    "price": "1350,00"
  },
  {
    "name": "Beta-2-glikoprotein I IgG",
    "price": "1350,00"
  },
  {
    "name": "Anti SCL 70 At",
    "price": "1550,00"
  },
  {
    "name": "Anti centromerni protein B At",
    "price": "1550,00"
  },
  {
    "name": "S-At na acetilholinske receptore",
    "price": "4300,00"
  },
  {
    "name": "Anti U-1 RNP At (RNP/Sm)",
    "price": "2100,00"
  },
  {
    "name": "Aquaporin IgM At",
    "price": "2800,00"
  },
  {
    "name": "Aquaporin 4 At",
    "price": "2800,00"
  },
  {
    "name": "S-Aquaporin 4 IgG At",
    "price": "2800,00"
  },
  {
    "name": "K-Endomizijalna IgA At",
    "price": "2300,00"
  },
  {
    "name": "Endomizijalna IgG At",
    "price": "2300,00"
  },
  {
    "name": "S-Trombocitna IgA At",
    "price": "2350,00"
  },
  {
    "name": "Trombocitna IgM At",
    "price": "2350,00"
  },
  {
    "name": "Trombocitna IgG At",
    "price": "2350,00"
  },
  {
    "name": "ASTA",
    "price": "3700,00"
  },
  {
    "name": "MUSK At",
    "price": "7200,00"
  },
  {
    "name": "Penfigus vulgaris At",
    "price": "7000,00"
  },
  {
    "name": "K-TPHA",
    "price": "1150,00"
  },
  {
    "name": "S-Transglutaminska IgA At",
    "price": "1350,00"
  },
  {
    "name": "S-Transglutaminska IgG At",
    "price": "1350,00"
  },
  {
    "name": "ANA(antinuklearna At)",
    "price": "1300,00"
  },
  {
    "name": "K-Anti IA2(Islet Ag At)",
    "price": "2000,00"
  },
  {
    "name": "LA1/LA2",
    "price": "2000,00"
  },
  {
    "name": "Anti C1q At",
    "price": "1250,00"
  },
  {
    "name": "Anti GBM At",
    "price": "1650,00"
  }
]