export const alergijaINetolerancijaNaHranu = [
  {
    "name": "Lesnik F17",
    "price": "1800,00"
  },
  {
    "name": "Pcela I1",
    "price": "1800,00"
  },
  {
    "name": "Ambrozija(alergija)",
    "price": "1800,00"
  },
  {
    "name": "S-Test intolerancije na hranu 90",
    "price": "24900,00"
  },
  {
    "name": "Test intolerancije na hranu 44",
    "price": "12000,00"
  },
  {
    "name": "Test intolerancije na hranu 22",
    "price": "6000,00"
  },
  {
    "name": "Inhalacioni panel",
    "price": "6000,00"
  },
  {
    "name": "S-Nutritivni panel ( 20 alergena )",
    "price": "6000,00"
  },
  {
    "name": "K-Univerzalni alergijski panel",
    "price": "6000,00"
  },
  {
    "name": "Screening test netol. na hranu",
    "price": "3000,00"
  },
  {
    "name": "Piletina f83",
    "price": "1800,00"
  },
  {
    "name": "Belance F1",
    "price": "1800,00"
  },
  {
    "name": "Mleko F2",
    "price": "1800,00"
  },
  {
    "name": "Psenica G15",
    "price": "1800,00"
  },
  {
    "name": "OSLIC F307",
    "price": "1800,00"
  },
  {
    "name": "SKUSA F174",
    "price": "2600,00"
  },
  {
    "name": "Inhalacioni panel(30 alergena)",
    "price": "6500,00"
  },
  {
    "name": "K-Alfa-laktalbumin(f76)",
    "price": "1800,00"
  },
  {
    "name": "K-Beta-laktoglobulin(f77)",
    "price": "1800,00"
  },
  {
    "name": "Kazein(f78)",
    "price": "1800,00"
  },
  {
    "name": "Aspirin(acetilsalicilna kiselina)C201",
    "price": "1800,00"
  },
  {
    "name": "Pseca dlaka",
    "price": "1800,00"
  },
  {
    "name": "Nutritivni panel (30 aleregena)",
    "price": "6500,00"
  },
  {
    "name": "Kikiriki F13",
    "price": "1800,00"
  }
]