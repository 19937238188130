export const testNaDroge = [
  {
    "name": "U-Test na opojne droge ( panel 5 )",
    "price": "2500,00"
  },
  {
    "name": "Barbiturati ( urin )",
    "price": "600,00"
  },
  {
    "name": "Benzodiazepini ( urin )",
    "price": "600,00"
  },
  {
    "name": "U-Test na droge (panel 10)",
    "price": "3500,00"
  }
]
