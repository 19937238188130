import React from "react";
import Header from "./components/Header";
import MainSwitch from "./components/_MainSwitch";
import Footer from "./components/Footer";
import "./scss/main.scss";

const App = () => {
  window.scrollTo(0, 0);
  return (
    <main className="main">
      <Header />
      <MainSwitch />
      <Footer />
    </main>
  );
};

export default App;
