export const urin24 = [
  {
    "name": "dU-Kalijum-urin",
    "price": "200,00"
  },
  {
    "name": "dU-Natrijum-urin",
    "price": "200,00"
  },
  {
    "name": "dU-Proteini-urin",
    "price": "250,00"
  },
  {
    "name": "dU-Mikroalbumin",
    "price": "800,00"
  },
  {
    "name": "dU-Urea-urin",
    "price": "150,00"
  },
  {
    "name": "dU-Klirens uree",
    "price": "300,00"
  },
  {
    "name": "dU-Klirens kreatinina",
    "price": "300,00"
  },
  {
    "name": "dU-Klirens mokra?ne kiseline",
    "price": "300,00"
  },
  {
    "name": "dU-Kalcijum-urin",
    "price": "200,00"
  },
  {
    "name": "dU-Alfa amilaza - 24 urin",
    "price": "350,00"
  },
  {
    "name": "dU-Mokracna kiselina-urin",
    "price": "150,00"
  },
  {
    "name": "dU-Kreatinin-urin",
    "price": "150,00"
  },
  {
    "name": "dU-Kateholamini-urin",
    "price": "4000,00"
  },
  {
    "name": "dU-HVA",
    "price": "2100,00"
  },
  {
    "name": "dU-Vanilmandelicna kiselina",
    "price": "2500,00"
  },
  {
    "name": "dU-Cink-urin",
    "price": "700,00"
  },
  {
    "name": "dU-Kortizol-urin",
    "price": "850,00"
  },
  {
    "name": "dU-5-HIAA",
    "price": "1300,00"
  },
  {
    "name": "dU-Fosfor-urin",
    "price": "200,00"
  },
  {
    "name": "Glukoza - urin",
    "price": "110,00"
  },
  {
    "name": "Magnezijum - urin",
    "price": "200,00"
  },
  {
    "name": "Hloridi - urin",
    "price": "200,00"
  },
  {
    "name": "dU-Porfobilinogen",
    "price": "1200,00"
  },
  {
    "name": "Bakar - urin",
    "price": "2700,00"
  },
  {
    "name": "K-Uroporfirin",
    "price": "1200,00"
  },
  {
    "name": "K-Koproporfirin",
    "price": "1200,00"
  },
  {
    "name": "Oksalati - urin",
    "price": "1500,00"
  },
  {
    "name": "Cistin-urin",
    "price": "1800,00"
  },
  {
    "name": "Oksalati u urinu",
    "price": "1700,00"
  },
  {
    "name": "K-ALA (aminolevolulinska kiselina)",
    "price": "1200,00"
  },
  {
    "name": "K-Citrati u 24h urinu",
    "price": "1800,00"
  }
]