export const spermogram = [
  {
    "name": "Smt-Spermogram",
    "price": "2500,00"
  },
  {
    "name": "Smt-Cink u seminalnoj plazmi",
    "price": "800,00"
  },
  {
    "name": "Smt-Fruktoza u seminalnoj plazmi",
    "price": "800,00"
  },
  {
    "name": "Smt-Kisela fosf. u seminalnoj plazmi",
    "price": "600,00"
  }
]