import React from "react";

const backgroundImage = {
  contact: require("../../assets/images/contact.png"),
  onama: require("../../assets/images/onama.png")
};

const BackgroundImage = props => {
  const { image } = props;
  return (
    <div className="backgroundImage">
      <img src={backgroundImage[image]} alt="trougao" />
    </div>
  );
};

export default BackgroundImage;
