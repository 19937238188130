import React, { Component } from "react";
import logo from "../../logo.png";
import { Link, withRouter } from "react-router-dom";
import { Spin } from "react-burgers";

class MobileHeader extends Component {
  render() {
    const { location, burger } = this.props;
    return (
      <>
        {burger ? (
          <nav className="mobile--nav main-padding">
            <div className="nav--left">
              <Link
                onClick={() => {
                  this.setState({ burger: !this.state.burger });
                }}
                className={location.pathname === "/" ? " active" : ""}
                to="/"
              >
                Početna
              </Link>
              <Link
                onClick={() => {
                  this.setState({ burger: !this.state.burger });
                }}
                className={location.pathname === "/onama" ? " active" : ""}
                to="/onama"
              >
                O nama
              </Link>
              <Link
                onClick={() => {
                  this.setState({ burger: !this.state.burger });
                }}
                className={location.pathname === "/lokacija" ? " active" : ""}
                to="/lokacija"
              >
                Lokacija
              </Link>
              <Link
                onClick={() => {
                  this.setState({ burger: !this.state.burger });
                }}
                className={location.pathname === "/kontakt" ? " active" : ""}
                to="/kontakt"
              >
                Kontakt
              </Link>
            </div>
            <div className="nav--right">
              <p>
                Pozovite nas na{"   "}
                <span className="number" style={{ marginLeft: "5px" }}>
                  <a href="tel:0116306713">011/630 67 13</a>
                </span>
                .
              </p>
              <p>Naše osoblje će Vam pomoći oko svih dodatnih informacija.</p>
            </div>
          </nav>
        ) : null}
      </>
    );
  }
}

const MobileHeaderWithRouter = withRouter(MobileHeader);

class Header extends React.Component {
  state = {
    burger: false
  };
  render() {
    const { location } = this.props;
    const { burger } = this.state;
    return (
      <header>
        <div className="header--wrapper main-padding">
          <Link to="/">
            <img alt="maklab-logo" className="header--logo" src={logo} />
          </Link>
          <nav className="header--main-nav">
            <Link className={location.pathname === "/" ? " active" : ""} to="/">
              Početna
            </Link>
            <Link className={location.pathname === "/onama" ? " active" : ""} to="/onama">
              O nama
            </Link>
            <Link className={location.pathname === "/lokacija" ? " active" : ""} to="/lokacija">
              Lokacija
            </Link>
            <Link className={location.pathname === "/kontakt" ? " active" : ""} to="/kontakt">
              Kontakt
            </Link>
          </nav>
          <Spin
            width={30}
            lineHeight={3}
            lineSpacing={7}
            borderRadius={2}
            padding="0px"
            active={burger}
            onClick={() => {
              this.setState(prevState => ({ burger: !prevState.burger }));
            }}
          />
          <MobileHeaderWithRouter burger={burger} className="header--mobile-nav" />
        </div>
      </header>
    );
  }
}

export default withRouter(Header);
