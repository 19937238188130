import React from "react";
import Button from "../_FormComponents/Button";
import Picture from "../Picture";
import { Link } from "react-router-dom";
import labpic from "../../assets/images/lab-cover.png";

const HomeCover = () => {
  return (
    <section className="home--cover main-padding">
      <div className="text">
        <h1>MakLab</h1>
        <h3>Biohemijska laboratorija</h3>
        <p>
          Laboratorija MakLab osnovana je sa ciljem da pruži usluge kvalitetne
          laboratorijske dijagnostike. Stručan kadar sa bogatim kliničkim i
          radnim iskustvom, kao i savremena oprema, omogućavaju kvalitetnu i
          brzu obradu biološkog materijala.
        </p>
        <Link to="./lokacija">
          <Button color="red" text="Kako do nas" />
        </Link>
        {/*<p style={{ marginTop: "50px", fontWeight: "bold" }}>
          Novo u Mak Lab laboratorijama :
          <section style={{ width: "100%" }}>
            U saradnji sa Synlab - om iz Beča kod nas sada možete uraditi Farmakogenetički profil ( pravi lek u pravo
            vreme), PHI test ( test kojim se karcinom prostate otkriva bez biopsije) i nove prenatalne NeoBona testove!
          </section>
        </p>*/}
        <Actions />
      </div>
      <Picture image={labpic} />
    </section>
  );
};

export default HomeCover;

const Actions = () => {
  return (
    <div style={{ margin: "50px 0" }}>
      <h4 style={{ color: "red", fontWeight: "bold", margin: "10px 0" }}>
        AKCIJA - DO KRAJA NOVEMBRA 2022.
      </h4>
      <Single
        title={"Panel za muškarce"}
        oldPrice={"4926,00"}
        newPrice={"3900,00"}
      />
      <Single
        title={"Panel za žene"}
        oldPrice={"4720,00"}
        newPrice={"3300,00"}
      />
      <Single
        title={"Panel - štitna žlezda"}
        oldPrice={"4120,00"}
        newPrice={"3296,00"}
      />
      <Single
        title={"Panel za decu"}
        oldPrice={"2090,00"}
        newPrice={"1500,00"}
      />
      <Single
        title={"Panel - anemija"}
        oldPrice={"2000,00"}
        newPrice={"1000,00"}
      />
      <Single
        title={"Adeno/rota virusi + koprokultura"}
        oldPrice={"1830,00"}
        newPrice={"1300,00"}
      />
      <Single
        title={"Adeno/rota/noro/astro virusi"}
        oldPrice={"5000,00"}
        newPrice={"2500,00"}
      />
      <Single
        title={
          "Paket za žene - cervikalni/vaginalni bris + mycoplasma/ureaplasma"
        }
        oldPrice={"3560,00"}
        newPrice={"2500,00"}
      />
      <Single
        title={"Bris grla + brzi strepto A test"}
        oldPrice={"1480,00"}
        newPrice={"1100,00"}
      />
      <Single
        title={"SARS-CoV-2 Ag - brzi antigenski test"}
        oldPrice="1700,00"
        newPrice={"1200,00"}
      />

      <p style={{ fontWeight: "bold", fontSize: "14px", marginTop: "5px" }}>
        ZA VIŠE INFORMACIJA O NAŠIM AKCIJAMA POZVATI NA BROJEVE: <br />
        <a href="tel:+381116306713">011/630 67 13</a> <br />
        <a href="tel:+381113982260">011/398 22 60</a> <br />
        <a href="tel:+381652169266">065/216 92 66</a> <br />
        <a href="tel:+381652169269">065/216 92 69</a> <br />
      </p>
    </div>
  );
};

const Single = ({ title, oldPrice, newPrice }) => {
  return (
    <p style={{ fontWeight: "bold", margin: 0, fontSize: "14px" }}>
      {title.toUpperCase()}
      {oldPrice && (
        <span style={{ textDecoration: "line-through", margin: "0 5px" }}>
          {oldPrice}
        </span>
      )}

      <span style={{ color: "red", fontWeight: "bold", margin: "0 3px" }}>
        {newPrice}
      </span>
    </p>
  );
};


const Modal = () => {
  return <div className="custom-modal">
    <Actions />
  </div>
}
