import React from "react";
import HomeCover from "../../components/HomeCover";
import HomePreporuke from "../../components/HomePreporuke";
import HomeLab from "../../components/HomeLab";
import Helmet from "react-helmet";
import HomeUslugeResponsive from "../../components/HomeUslugeResponsive";
import HomePreporukeResponsive from "../../components/HomePreporukeResponsive";

const Home = props => {
  return (
    <>
      <Helmet>
        <title>{props.metaTags.title}</title>
        <meta
          name="description"
          content="Laboratorija MakLab osnovana je sa ciljem da pruži usluge kvalitetne laboratorijske dijagnostike."
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Uon Solutions" />
      </Helmet>
      <HomeCover />
      <HomeUslugeResponsive />
      <HomePreporuke />
      <HomePreporukeResponsive />
      <HomeLab />
    </>
  );
};

export default Home;
