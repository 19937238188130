export const urin = [
  {
    "name": "U-Urin (kval. sa sedimentom)",
    "price": "300,00"
  },
  {
    "name": "U-Alfa amilaza ( urin )",
    "price": "450,00"
  },
  {
    "name": "U-Osmolalitet -urin",
    "price": "700,00"
  },
  {
    "name": "U-Mikroalbumin pojed. uzorak",
    "price": "850,00"
  },
  {
    "name": "U-Natrijum- urin pojed. uzorak",
    "price": "155,00"
  },
  {
    "name": "U-Kalijum-urin pojed. uzorak",
    "price": "155,00"
  },
  {
    "name": "U-Kreatinin -prvi jutarnji urin",
    "price": "150,00"
  },
  {
    "name": "Urea-prvi jutarnji urin",
    "price": "150,00"
  },
  {
    "name": "dU-Elektroforeza proteina-urin",
    "price": "1650,00"
  },
  {
    "name": "U-Bence Jones proteini",
    "price": "600,00"
  },
  {
    "name": "dU-Imunoelektroforeza - urin",
    "price": "4200,00"
  },
  {
    "name": "U-Kalcijum-urin pojed. uzorak",
    "price": "150,00"
  },
  {
    "name": "U-Proteini- pojedina?ni uzorak",
    "price": "300,00"
  },
  {
    "name": "U-Mokra?na kis.-pojedin.uzorak",
    "price": "150,00"
  },
  {
    "name": "U-Albumin-kreatinin odnos",
    "price": "1,00"
  }
]