import React from "react";
import Button from "../_FormComponents/Button";
import Pdf from "../../assets/images/file-pdf.png";
import UonLogo from "../../assets/images/uonlogo.png";
import { Link } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";

const handlePdfClick = str => {
  ReactPixel.trackCustom("PDF Preview", str);
};

const Footer = () => {
  return (
    <footer className="footer main-padding">
      <div className="main--footer">
        <div className="footer--info">
          <h3>Mak Lab</h3>
          <span>Biohemijska</span>
          <span>Laboratorija</span>
          <span>Generala Štefanika 18 / lok 2,</span>
          <span>Voždovac, Beograd</span>
          <a href="tel:+381116306713">011/630 67 13</a>
          <a href="tel:+381652169266">065/216 92 66</a>
          <a href="tel:+381652169269">065/216 92 69</a>
          <a className='email' href="mailto:maklaboratorija@gmail.com">maklaboratorija@gmail.com</a>
        </div>
        <div className="footer--info">
          <h3>Mak Lab</h3>
          <span>Mikrobiološka</span>
          <span>Laboratorija</span>
          <span>Meštrovićeva 30b,</span>
          <span>Voždovac, Beograd</span>
          <a href="tel:+381113982260"> 011/398 22 60</a>
          <a href="tel:+381693169269">069/316 92 69</a>
          <a className='email' href="mailto:maklaboratorija@gmail.com">maklaboratorija@gmail.com</a>
        </div>
        <div className="footer--docs">
          <div className="footer--docs--box">
            <a
              onClick={() => handlePdfClick("Potvrda o registraciji firme")}
              target="blank"
              href="http://www.maklab.rs/resenje.pdf"
            >
              <img alt="rešenje" src={Pdf} />
              <p>
                Potvrda o
                <br /> registraciji
                <br /> firme
              </p>
            </a>
          </div>
          <div className="footer--docs--box">
            <a
              onClick={() => handlePdfClick("Mikrobiološka laboratorija")}
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.maklab.rs/Resenje-MakLabmikrobiologija.pdf"
            >
              <img alt="rešenje" src={Pdf} />
              <p>
                Potvrda
                <br /> Mikrobiološka
                <br /> laboratorija
              </p>
            </a>
          </div>
          <Link to="/kontakt">
            <Button color="red" text="Kontaktirajte nas" />
          </Link>
        </div>
      </div>
      <div className="bottom--footer main--padding">
        <p>
          &copy; <span>Maklab</span> {new Date().getFullYear()}
        </p>
        <p>
          <a target="_blank" rel="noopener noreferrer" href="https://uon.rs/">
            Developed by <img alt="uon logo" src={UonLogo} />
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
