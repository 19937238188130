export const tumorMarkeri = [
  {
    "name": "S-PSA",
    "price": "950,00"
  },
  {
    "name": "S-Free PSA",
    "price": "1100,00"
  },
  {
    "name": "indeks PSA",
    "price": "1,00"
  },
  {
    "name": "S-AFP",
    "price": "950,00"
  },
  {
    "name": "S-CEA",
    "price": "950,00"
  },
  {
    "name": "S-CA 50",
    "price": "2000,00"
  },
  {
    "name": "S-CA 19-9",
    "price": "1350,00"
  },
  {
    "name": "S-CA 15-3",
    "price": "1350,00"
  },
  {
    "name": "S-CA 125",
    "price": "1350,00"
  },
  {
    "name": "S-CA 72-4",
    "price": "1350,00"
  },
  {
    "name": "S-Beta2 mikroglobulin",
    "price": "1100,00"
  },
  {
    "name": "S-CYFRA 21-1",
    "price": "1450,00"
  },
  {
    "name": "S-NSE",
    "price": "1350,00"
  },
  {
    "name": "ROMA index",
    "price": "1,00"
  },
  {
    "name": "S-HE4 (human epididymis protein 4)",
    "price": "2500,00"
  },
  {
    "name": "S-Protein S-100",
    "price": "3150,00"
  },
  {
    "name": "P-Hromogranin A",
    "price": "2250,00"
  },
  {
    "name": "UBC ( mokraćna bešika )",
    "price": "3100,00"
  },
  {
    "name": "S-SCC antigen",
    "price": "2500,00"
  },
  {
    "name": "TATI (grli? materice)",
    "price": "2700,00"
  }
]