import React, { Component } from "react";
import InlineSVG from "svg-inline-react";
import Pricelist from "../Pricelist/Pricelist";

class Accordion extends Component {
  state = {
    toggle: false,
    showDisabledMessage: false
  };

  render() {
    const { recommend, onClick, prices, children, icon, name, disabledMessage, disabled, activeName } = this.props;
    const { showDisabledMessage } = this.state;
    return (
      <>
        <div className="accordion--header" onClick={onClick}>
          <p className={disabled ? "disabled" : ""}>{name}</p>
          {showDisabledMessage ? <span className="disabled-message">{disabledMessage}</span> : null}
          <InlineSVG src={icon} />
        </div>
        {recommend ? null : activeName === name ? <Pricelist data={prices} /> : null}

        {activeName === name ? children : null}
      </>
    );
  }
}

export default Accordion;
