import React from "react";

const Pricelist = props => {
  // ReactPixel.trackCustom()
  const { data } = props;
  console.log('data', data)
  return (
    <div className="price--list--open--container">
      {data.map(({ price, name }, i) => {
        return (
          <div className="price--list--open" key={i}>
            <span>{name}</span>
            <span>{price} din</span>
          </div>
        );
      })}
    </div>
  );
};

export default Pricelist;
