export const kostaniMarkeri = [
  {
    "name": "S-Beta CrossLaps",
    "price": "1500,00"
  },
  {
    "name": "S-Vitamin D (25-OH) total",
    "price": "2150,00"
  },
  {
    "name": "S-Osteokalcin",
    "price": "1500,00"
  }
]
