import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "../../pages/Home";
import Contact from "../../pages/Contact";
import About from "../../pages/About";
import Location from "../../pages/Location";

import { meta } from "../../config";

const MainSwitch = () => {
  return (
    <Switch>
      <Route
        exact
        path="/"
        component={() => {
          return <Home metaTags={meta.home} />;
        }}
      />
      <Route
        path="/onama"
        component={() => {
          return <About metaTags={meta.about} />;
        }}
      />
      <Route
        path="/lokacija"
        component={() => {
          return <Location metaTags={meta.location} />;
        }}
      />
      <Route
        path="/kontakt"
        component={() => {
          return <Contact metaTags={meta.contact} />;
        }}
      />
    </Switch>
  );
};

export default MainSwitch;
