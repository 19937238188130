export const ostalo = [
  {
    "name": "Biopsija koze",
    "price": "5400,00"
  },
  {
    "name": "inaktivacija seruma",
    "price": "200,00"
  },
  {
    "name": "Vadjenje krvi",
    "price": "120,00"
  },
  {
    "name": "Patronaza (grad)",
    "price": "700,00"
  },
  {
    "name": "Patronaza (van grada)",
    "price": "1500,00"
  },
  {
    "name": "Dostava rezultata",
    "price": "300,00"
  },
  {
    "name": "K-Alkohol u krvi",
    "price": "1200,00"
  },
  {
    "name": "Methemoglobin",
    "price": "1100,00"
  },
  {
    "name": "Peptidurija",
    "price": "9300,00"
  },
  {
    "name": "Patronaza Zemun",
    "price": "1500,00"
  },
  {
    "name": "Patronaza Stepa Stepanovi?",
    "price": "500,00"
  },
  {
    "name": "K-Alkohol u urinu",
    "price": "1450,00"
  }
]