import React from "react";
import ContactForm from "../../components/_FormComponents/ContactForm";
import Helmet from "react-helmet";
import BackgroundImage from "../../components/BackgroundImage";

const Contact = props => {
  return (
    <>
      <Helmet>
        <title>{props.metaTags.title}</title>
        <meta
          name="description"
          content="Laboratorija MakLab osnovana je sa ciljem da pruži usluge kvalitetne laboratorijske dijagnostike."
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Uon Solutions" />
      </Helmet>
      <section className="contact main-padding">
        <h3>Postavite nam pitanje</h3>
        <ContactForm />
        <div className="contact--main">
          <div className="contact--text">
            <div>
              <h3>Izdavanje rezultata</h3>
              <p>
                Izdavanje rezulata za većinu biohemijskih i hematoloških analiza, za hormone, tumor markere, urine i
                spermograme je 1-3 sata posle davanja uzorka.
              </p>
            </div>
            <div>
              <h3>Niste sigurni koja vam je analiza potrebna?</h3>
              <p>
                Pozovite nas na{" "}
                <span className="number">
                  <a href="tel:+381116306713">011/630 67 13</a>
                </span>
                <br />
                Naše osoblje će Vam pomoći oko svih dodatnih informacija.
              </p>
            </div>
          </div>
          <div className="contact--info">
            <h3>Kontakt info</h3>
            <div>
              <p>
                <i className="fas fa-phone" />
                <a href="tel:+381116306713">011/630 67 13</a>
              </p>
              <p>
                <i className="fas fa-mobile-alt" />
                <a href="tel:+381638508299">063/85 08 299</a>
              </p>
              <p>
                <i className="fas fa-envelope" />
                <a href="mailto:maklaboratorija@gmail.com">maklaboratorija@gmail.com</a>
              </p>
              <p>
                <i className="fas fa-globe" />
                www.maklab.rs
              </p>
              <p>
                <i className="far fa-clock" />
                Ponedeljak - Petak: 07 do 19h
              </p>
              <p>
                <i className="far fa-clock" />
                Subota: 08 do 15h
              </p>
            </div>
          </div>
        </div>
        <BackgroundImage image="contact" />
      </section>
    </>
  );
};

export default Contact;
