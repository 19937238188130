let preporuke = {
  "Vadjenje Krvi": {
    title: "Vadjenje Krvi",
    icon: "krv",
    items: [
      {
        text: "Dan pre dolaska u laboratoriju uzimajte lakšu hranu do 19 h"
      },
      {
        text:
          "Ujutru pre dolaska u laboratoriju ne uzimate hranu i piće, osim vode, izostavite duvan"
      },
      {
        text:
          "48 h pre uzimanja krvi izbegavati intenzivnu fizičku aktivnost, ne konzumirati alkoholna pića"
      },
      {
        text:
          "Ukoliko imate bilo koju terapiju, jutarnju dozu uzmite nakon vađenja krvi"
      },
      {
        text:
          "Ukoliko uzimate preparate gvožđa u bilo kom obliku ili pijete vitaminske preparate obogaćene gvožđem,odložite kontrolu gvožđa u serumu za najmanje 10 dana.Preporučuje se jutarnja kontrola gvožđa(varira u toku dana)."
      },
      {
        text:
          "Ukoliko dovodite dete na vađenje krvi, rečima koje su prilagođene njegovom uzrastu, objasnite mu šta ga očekuje u laboratoriji"
      }
    ]
  },
  "Saveti za test opterećenja glukozom (OGTT test)": {
    title: "Saveti za test opterećenja glukozom (OGTT test)",
    icon: "secer",
    items: [
      {
        text:
          "Test opterećenja glukozom se izvodi u laboratoriji, u trajanju od 120-180 minuta (u zavisnosti od protokola koji je zahtevao Vaš lekar). "
      },

      {
        text:
          "Za vreme izvođenja samog testa pacijent treba da miruje, ne sme da jede, pije i puši."
      }
    ]
  },
  "Saveti za urin": {
    title: "Saveti za urin",
    icon: "urin",
    items: [
      {
        text:
          "Preporuka je uzeti prvi jutarnji urin (srednji mlaz) u sterilnu bočicu i najkasnije u roku od 2h doneti u laboratoriju."
      },
      {
        text:
          "Pre davanja uzorka za urinokulturu, obaviti detaljnu jutarnju higijenu, uhvatiti srednji mlaz i čuvati urin na hladnom najviše dva sata do dolaska u laboratoriju."
      },
      {
        text:
          " Ukoliko se koristi urokult, površinu sa podlogama uroniti u čašicu sa urinom do 1 minuta, urin prosuti a podloge vratiti u čašicu i držati na sobnoj temperaturi do dolaska u laboratoriju."
      }
    ]
  },
  "Saveti za bris grla i nosa": {
    title: "Saveti za bris grla i nosa",
    icon: "bris",
    items: [
      {
        text:
          "Bris grla treba uzeti ujutru, pre pranja zuba, unošenja hrane i vode."
      },
      {
        text: "Bris grla i nosa treba uzeti pre primene antibiotske terapije "
      }
    ]
  },
  "Saveti za spermogram i sprermokulturu": {
    title: "Saveti za spermogram i sprermokulturu",
    icon: "spermogram",
    items: [
      { text: "Za analizu sperme neophodna je apstinencija 3-7 dana." },
      {
        text:
          " Uzorak sperme se daje u laboratoriji u sterilnu bočicu ili se donosi u laboratorju na 37C u roku od 20 minuta."
      },
      {
        text:
          " Za spermokulturu pacijent pre davanja uzorka sperme treba da urinira i nije potrebna apstinencija."
      }
    ]
  },
  "Saveti za koprokulturu": {
    title: "Saveti za koprokulturu",
    icon: "koprokultura",
    items: [
      {
        text:
          "U sterilnu posudu (namenjenu za tu svrhu) stavlja se stolica veličine zrna graška, pri čemu se pokupe krvavi i sluzavi delovi stolice, ukoliko postoje."
      },
      {
        text:
          " Potrebno je pregledati najmanje 3 uzastopna uzorka stolice da bi se potvrdilo ili isključilo prisustvo patogenih bakterija."
      }
    ]
  },
  "Saveti za okultno krvarenje": {
    title: "Saveti za okultno krvarenje",
    icon: "krvarenje",
    items: [
      {
        text: "Ograničenja u ishrani nisu potrebna u toku pripreme za analizu."
      },
      {
        text:
          "Uzorak stolice ne uzimati tokom i tri dana nakon menstrualnog krvarenja, ako postoji vidljivo krvarenje iz hemoroida ili krv u urinu."
      },
      { text: "Takođe, uzorak ne treba uzimati ni u slučaju akutnog proliva." },
      {
        text:
          "Nekoliko dana pre uzomanja uzorka stolice ne preporučuje se uzimanje alkohola i lekova koji mogu izazvati gastrointestinalnu iritaciju, i dovasti do pojave krvi u stolici (aspirin, indometacin, fenilbutazon i dr.). Ove supstance ne treba koristiti 48 sati pre testiranja."
      }
    ]
  }
};

export default preporuke;
