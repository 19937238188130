export const hematologija = [
    {
      name: "K-Leukociti (WBC)",
      price: "130,00"
    },
    {
      name: "K-Leukocitarna formula",
      price: "200,00"
    },
    {
      name: "K-Trombociti",
      price: "130,00"
    },
    {
      name: "K-Retikulociti",
      price: "200,00"
    },
    {
      name: "K-Mikro sedimentacija",
      price: "170,00"
    },
    {
      name: "S-Krvna grupa",
      price: "2000,00"
    },
    {
      name: "S-RH antitela",
      price: "1650,00"
    },
    {
      name: "Coombsov test prosireni",
      price: "1650,00"
    },
    {
      name: "Histamin",
      price: "2000,00"
    },
    {
      name: "KS-Subpopulacija limfocita per.krvi",
      price: "4850,00"
    },
    {
      name: "ALP u leukocitima",
      price: "1700,00"
    },
    {
      name: "K-Patronaza NBG",
      price: "1200,00"
    },
    {
      name: "K-Uzimanje mikrobioloskog materijala",
      price: "250,00"
    },
    {
      name: "K-Metamefrin",
      price: "2800,00"
    },
    {
      name: "K-Normetamefrin",
      price: "2800,00"
    },
    {
      name: "K-Amonijak u krvi",
      price: "1100,00"
    },
    {
      name: "Trombofilija(PCR)",
      price: "14500,00"
    },
    {
      name: "Farmako-genetski profil",
      price: "120000,00"
    },
    {
      name: "HHV6(PCR)",
      price: "8500,00"
    },
    {
      name: "Patronaza",
      price: "1000,00"
    },
    {
      name: "Krvna slika 5Dif",
      price: "310,00"
    },
    {
      name: "Sedimentacija",
      price: "130,00"
    }
];
