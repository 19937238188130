export default {
  home: {
    title: "Maklab - biohemijska laboratorija",
    description:
      "Laboratorija MakLab osnovana je sa ciljem da pruži usluge kvalitetne laboratorijske dijagnostike. Stručan kadar sa bogatim kliničkim i radnim iskustvom, kao i savremena oprema, omogućavaju kvalitetnu i brzu obradu biološkog materijala.",
    keywords: "maklab, laboratorija, biohemija, dijagnostika, analiza krvi"
  },
  about: {
    title: "Maklab - O Nama",
    description:
      "Laboratorija MakLab osnovana je sa ciljem da pruži usluge kvalitetne laboratorijske dijagnostike. Stručan kadar sa bogatim kliničkim i radnim iskustvom, kao i savremena oprema, omogućavaju kvalitetnu i brzu obradu biološkog materijala.",
    keywords: "maklab, laboratorija, biohemija, dijagnostika, analiza krvi"
  },
  location: {
    title: "Maklab - Lokacija",
    description:
      "Laboratorija MakLab osnovana je sa ciljem da pruži usluge kvalitetne laboratorijske dijagnostike. Stručan kadar sa bogatim kliničkim i radnim iskustvom, kao i savremena oprema, omogućavaju kvalitetnu i brzu obradu biološkog materijala.",
    keywords: "maklab, laboratorija, biohemija, dijagnostika, analiza krvi"
  },
  contact: {
    title: "Maklab - Kontakt",
    description:
      "Laboratorija MakLab osnovana je sa ciljem da pruži usluge kvalitetne laboratorijske dijagnostike. Stručan kadar sa bogatim kliničkim i radnim iskustvom, kao i savremena oprema, omogućavaju kvalitetnu i brzu obradu biološkog materijala.",
    keywords: "maklab, laboratorija, biohemija, dijagnostika, analiza krvi"
  }
};
