export const biohemija = [
  {
    "name": "Amjodaron(nivo leka)",
    "price": "4500,00"
  },
  {
    "name": "PINP",
    "price": "4000,00"
  },
  {
    "name": "INTERLEUKIN 6 (IL6)",
    "price": "3600,00"
  },
  {
    "name": "Anti-MOG At",
    "price": "1700,00"
  },
  {
    "name": "Quantiferon",
    "price": "8500,00"
  },
  {
    "name": "IgG At na S-Protein(Covid)",
    "price": "2200,00"
  },
  {
    "name": "TACROLIMUS",
    "price": "6500,00"
  },
  {
    "name": "Cefalosporini C6",
    "price": "1800,00"
  },
  {
    "name": "Tetanus toxoid At",
    "price": "2500,00"
  },
  {
    "name": "S-Glukoza",
    "price": "110,00"
  },
  {
    "name": "S-Glukoza iz prsta",
    "price": "110,00"
  },
  {
    "name": "S-Urea",
    "price": "150,00"
  },
  {
    "name": "S-Kreatinin",
    "price": "150,00"
  },
  {
    "name": "S-Cistatin C",
    "price": "1900,00"
  },
  {
    "name": "S-Mokracna kiselina",
    "price": "150,00"
  },
  {
    "name": "S-Bilirubin ukupan",
    "price": "150,00"
  },
  {
    "name": "S-Bilirubin direktan",
    "price": "150,00"
  },
  {
    "name": "S-Proteini ukupni",
    "price": "150,00"
  },
  {
    "name": "S-Elektroforeza proteina",
    "price": "1220,00"
  },
  {
    "name": "S-Albumin",
    "price": "150,00"
  },
  {
    "name": "S-AST",
    "price": "150,00"
  },
  {
    "name": "S-ALT",
    "price": "150,00"
  },
  {
    "name": "S-ALP (Alkalna fosfataza)",
    "price": "150,00"
  },
  {
    "name": "S-Gama GT",
    "price": "150,00"
  },
  {
    "name": "Holinesteraza",
    "price": "480,00"
  },
  {
    "name": "S-Alfa amilaza",
    "price": "450,00"
  },
  {
    "name": "S-Pankreasna amilaza",
    "price": "500,00"
  },
  {
    "name": "S-Lipaza",
    "price": "500,00"
  },
  {
    "name": "S-Kisela fosfataza",
    "price": "280,00"
  },
  {
    "name": "S-Kisela fosfataza pros.",
    "price": "280,00"
  },
  {
    "name": "S-CK (kreatin kinaza)",
    "price": "250,00"
  },
  {
    "name": "S-CK-MB",
    "price": "380,00"
  },
  {
    "name": "S-TROPONIN T high sens.",
    "price": "1350,00"
  },
  {
    "name": "S-Miolglobin",
    "price": "1450,00"
  },
  {
    "name": "S-LDH (Laktat dehidrogenaza)",
    "price": "150,00"
  },
  {
    "name": "S-Natrijum",
    "price": "155,00"
  },
  {
    "name": "S-Kalijum",
    "price": "155,00"
  },
  {
    "name": "S-Hloridi",
    "price": "155,00"
  },
  {
    "name": "S-Kalcijum",
    "price": "150,00"
  },
  {
    "name": "S-Jonizovani kalcijum",
    "price": "400,00"
  },
  {
    "name": "S-Fosfor",
    "price": "150,00"
  },
  {
    "name": "S-Bakar",
    "price": "550,00"
  },
  {
    "name": "S-Cink",
    "price": "800,00"
  },
  {
    "name": "S-Magnezijum",
    "price": "150,00"
  },
  {
    "name": "S-Bikarbonati",
    "price": "200,00"
  },
  {
    "name": "S-Osmolalitet",
    "price": "700,00"
  },
  {
    "name": "S-Gvozdje",
    "price": "160,00"
  },
  {
    "name": "S-Feritin",
    "price": "1100,00"
  },
  {
    "name": "S-Transferin",
    "price": "800,00"
  },
  {
    "name": "S-Saturacija transferina",
    "price": "750,00"
  },
  {
    "name": "Solubilni transferinski receptori",
    "price": "2500,00"
  },
  {
    "name": "S-TIBC",
    "price": "160,00"
  },
  {
    "name": "S-UIBC",
    "price": "150,00"
  },
  {
    "name": "S-B12",
    "price": "1100,00"
  },
  {
    "name": "S-Folna kiselina",
    "price": "1100,00"
  },
  {
    "name": "S-Holesterol",
    "price": "150,00"
  },
  {
    "name": "S-HDL holesterol",
    "price": "160,00"
  },
  {
    "name": "S-LDL holesterol",
    "price": "160,00"
  },
  {
    "name": "S-Trigliceridi",
    "price": "150,00"
  },
  {
    "name": "Faktor rizika",
    "price": "1,00"
  },
  {
    "name": "Index ateroskleroze",
    "price": "1,00"
  },
  {
    "name": "S-Apoliprotein A1",
    "price": "500,00"
  },
  {
    "name": "S-Apoliprotein B",
    "price": "500,00"
  },
  {
    "name": "S-Lipoprotein a ( Lpa)",
    "price": "1000,00"
  },
  {
    "name": "P-Homocistein",
    "price": "2250,00"
  },
  {
    "name": "S-Totalni antioksidantni status",
    "price": "1800,00"
  },
  {
    "name": "S-OGTT (Opterecenje glukozom)",
    "price": "1100,00"
  },
  {
    "name": "K-HbA1C",
    "price": "950,00"
  },
  {
    "name": "K-Fruktozamin",
    "price": "950,00"
  },
  {
    "name": "S-Anti GAD",
    "price": "2250,00"
  },
  {
    "name": "Anti ICA antitela",
    "price": "4500,00"
  },
  {
    "name": "S-ACE",
    "price": "1350,00"
  },
  {
    "name": "S-Eritropoetin",
    "price": "2050,00"
  },
  {
    "name": "S-Prokalcitonin",
    "price": "2450,00"
  },
  {
    "name": "Pro-BNP",
    "price": "3350,00"
  },
  {
    "name": "BNP",
    "price": "2800,00"
  },
  {
    "name": "S-Imunoelektroforeza proteina",
    "price": "4200,00"
  },
  {
    "name": "eGFR",
    "price": "100,00"
  },
  {
    "name": "S -MIOGLOBIN",
    "price": "1450,00"
  },
  {
    "name": "K-Selen",
    "price": "3000,00"
  },
  {
    "name": "K-Bartonella IgG At",
    "price": "3000,00"
  },
  {
    "name": "Bartonella IgM At",
    "price": "3000,00"
  },
  {
    "name": "K-Galaktomanan test",
    "price": "3000,00"
  },
  {
    "name": "K-Vitamin K(1+2)",
    "price": "3750,00"
  },
  {
    "name": "S-Vitamin B1",
    "price": "2100,00"
  },
  {
    "name": "K-Ukupne žučne kiseline",
    "price": "1500,00"
  },
  {
    "name": "K-AQP-4(Akvaporin At)",
    "price": "3000,00"
  },
  {
    "name": "K-Troponin I",
    "price": "1350,00"
  },
  {
    "name": "K-Gluten F79",
    "price": "1800,00"
  },
  {
    "name": "Aviditet CMV IgG",
    "price": "2650,00"
  },
  {
    "name": "Protein/kreatinin odno(urin)",
    "price": "1,00"
  },
  {
    "name": "Analiza kamena",
    "price": "1200,00"
  },
  {
    "name": "INFLIXIMAB nivo leka",
    "price": "5700,00"
  },
  {
    "name": "Remicade-nivo leka",
    "price": "5700,00"
  },
  {
    "name": "Quantiferon Gold",
    "price": "6000,00"
  },
  {
    "name": "DAO(Diamino-Oksidaza)",
    "price": "2800,00"
  },
  {
    "name": "Levetiracetam (KEPPRA)",
    "price": "3500,00"
  },
  {
    "name": "ECP(Astma)",
    "price": "2000,00"
  },
  {
    "name": "Hitotriozidaza",
    "price": "6700,00"
  },
  {
    "name": "Živa",
    "price": "3000,00"
  },
  {
    "name": "IGG subklase (1,2,3,4)",
    "price": "4300,00"
  }
]