export const mikrobiologija = [
  {
    "name": "SARS-CoV-2 Ag test",
    "price": "1200,00"
  },
  {
    "name": "Covid IgM+IgG hromatografski test",
    "price": "1800,00"
  },
  {
    "name": "Bris usne duplje",
    "price": "550,00"
  },
  {
    "name": "Bris rane",
    "price": "620,00"
  },
  {
    "name": "Bris koze",
    "price": "600,00"
  },
  {
    "name": "Bris oka",
    "price": "590,00"
  },
  {
    "name": "Bris uha",
    "price": "590,00"
  },
  {
    "name": "Bris uretre",
    "price": "550,00"
  },
  {
    "name": "Perianalni bris",
    "price": "400,00"
  },
  {
    "name": "Cervikalni bris",
    "price": "590,00"
  },
  {
    "name": "Bris vulve",
    "price": "590,00"
  },
  {
    "name": "Chlamydia trachomatis DIF",
    "price": "1100,00"
  },
  {
    "name": "Sputum",
    "price": "600,00"
  },
  {
    "name": "Dlaka na gljivice",
    "price": "500,00"
  },
  {
    "name": "Bris grla",
    "price": "530,00"
  },
  {
    "name": "Dlaka na dermatofite",
    "price": "750,00"
  },
  {
    "name": "Bris nosa",
    "price": "530,00"
  },
  {
    "name": "Helminti-dokazivanje i ind.",
    "price": "550,00"
  },
  {
    "name": "Bris jezika",
    "price": "530,00"
  },
  {
    "name": "Protozoe-dokazivanje i ind.",
    "price": "550,00"
  },
  {
    "name": "ECHINOCOCCUS ukupna At IHA",
    "price": "1400,00"
  },
  {
    "name": "Stolica na tri preparata",
    "price": "1200,00"
  },
  {
    "name": "Urinokultura",
    "price": "560,00"
  },
  {
    "name": "Spermokultura",
    "price": "590,00"
  },
  {
    "name": "Adeno i Rota virus u stolici",
    "price": "1100,00"
  },
  {
    "name": "Koprokultura",
    "price": "730,00"
  },
  {
    "name": "S-H. pylori IgA",
    "price": "1270,00"
  },
  {
    "name": "S-H. pylori IgG",
    "price": "1270,00"
  },
  {
    "name": "S-H. pylori Ag ( feces )",
    "price": "1450,00"
  },
  {
    "name": "Izdisajni test na H. Pilory",
    "price": "3600,00"
  },
  {
    "name": "S-Chlamydia trach. At IgM",
    "price": "1350,00"
  },
  {
    "name": "S-Chlamydia trach. At IgG",
    "price": "1350,00"
  },
  {
    "name": "S-Chlamydia pneumoniae At IgM",
    "price": "1350,00"
  },
  {
    "name": "S-Chlamydia pneumoniae At IgG",
    "price": "1350,00"
  },
  {
    "name": "S-Mycoplasma pneum. At IgM",
    "price": "1350,00"
  },
  {
    "name": "S-Mycoplasma pneum. At IgG",
    "price": "1350,00"
  },
  {
    "name": "S-Borelia burg. At IgM",
    "price": "1350,00"
  },
  {
    "name": "S-Borelia burg. At IgG",
    "price": "1350,00"
  },
  {
    "name": "S-Anti Toxoplasma IgM",
    "price": "1300,00"
  },
  {
    "name": "S-Anti Toxoplasma IgG",
    "price": "1300,00"
  },
  {
    "name": "S-Aviditet Toxoplasma",
    "price": "1650,00"
  },
  {
    "name": "Mikološki pregled - urin",
    "price": "500,00"
  },
  {
    "name": "Mikološki pregled - bris",
    "price": "500,00"
  },
  {
    "name": "Sterilnost instrumenata",
    "price": "280,00"
  },
  {
    "name": "Vaginalni bris",
    "price": "570,00"
  },
  {
    "name": "S-At na cisticerkozu IgG ( Taenia solium )",
    "price": "2000,00"
  },
  {
    "name": "Bris prepucijuma",
    "price": "570,00"
  },
  {
    "name": "Ureaplasma i Mycoplasma-",
    "price": "2400,00"
  },
  {
    "name": "indentifikacija,antibiog",
    "price": ""
  },
  {
    "name": "Bakterijska vaginoza",
    "price": "500,00"
  },
  {
    "name": "S-Trichinella spiralis IgG",
    "price": "1500,00"
  },
  {
    "name": "Brucella IgG At",
    "price": "2530,00"
  },
  {
    "name": "Mikološki pregled - feces",
    "price": "500,00"
  },
  {
    "name": "Candida albicans IgM",
    "price": "1500,00"
  },
  {
    "name": "Candida albicans IgG",
    "price": "1500,00"
  },
  {
    "name": "Rektalni bris",
    "price": "460,00"
  },
  {
    "name": "Listeria monocytogenes IgG",
    "price": "1350,00"
  },
  {
    "name": "Brucella ( BAB )",
    "price": "950,00"
  },
  {
    "name": "Citološki pregled",
    "price": "3600,00"
  },
  {
    "name": "S-Echinococus uk. At (hemaglutin.)",
    "price": "1400,00"
  },
  {
    "name": "Dermatofite ( skarifikat ) kultura i preparat",
    "price": "1200,00"
  },
  {
    "name": "Demodex ( bris )",
    "price": "450,00"
  },
  {
    "name": "Borellia b. Western -Blot IgM",
    "price": "3650,00"
  },
  {
    "name": "Borellia b. Western-Blot IgG",
    "price": "3650,00"
  },
  {
    "name": "Echinococcus Ag",
    "price": "2700,00"
  },
  {
    "name": "Campylobacter spp - kultura",
    "price": "600,00"
  },
  {
    "name": "F-Clostridium diff. toxini",
    "price": "2450,00"
  },
  {
    "name": "Yersinia IgA at",
    "price": "1500,00"
  },
  {
    "name": "Yersinia IgG at",
    "price": "1500,00"
  },
  {
    "name": "LEPRA",
    "price": "1350,00"
  },
  {
    "name": "MALARIJA",
    "price": "1650,00"
  },
  {
    "name": "GONOREJA",
    "price": "500,00"
  },
  {
    "name": "Demodex spp (otisak kože)",
    "price": "500,00"
  },
  {
    "name": "Bordetella pertussis IgG",
    "price": "1980,00"
  },
  {
    "name": "Bordetella pertussis IgM",
    "price": "1980,00"
  },
  {
    "name": "K-Toxocara canis At",
    "price": "3500,00"
  },
  {
    "name": "QuantiFERON -TB Gold test",
    "price": "5000,00"
  },
  {
    "name": "Strepto A brzi test",
    "price": "950,00"
  },
  {
    "name": "K-Mikroskopski pregled na GONOREJU",
    "price": "500,00"
  },
  {
    "name": "TULAREMIJA At",
    "price": "2000,00"
  },
  {
    "name": "Aviditet toxoplasma IgG",
    "price": "2650,00"
  },
  {
    "name": "Yersinia enter. kultura",
    "price": "450,00"
  },
  {
    "name": "S-VDRL",
    "price": "1150,00"
  },
  {
    "name": "K-Sputum(lowenstein)",
    "price": "5500,00"
  },
  {
    "name": "K-Identifikacija atipi?nih mikobakterija(PCR)",
    "price": "6500,00"
  },
  {
    "name": "K-Bris radne površine",
    "price": "600,00"
  },
  {
    "name": "Bris glansa",
    "price": "550,00"
  },
  {
    "name": "ADENO/ROTA/NORO/ASTRO-virusi u stolici",
    "price": "5000,00"
  },
  {
    "name": "Punktat (bakteriološki)",
    "price": "600,00"
  },
  {
    "name": "Ureaplasma(Urin)",
    "price": "3000,00"
  },
  {
    "name": "Influenza A i B brzi test",
    "price": "1400,00"
  },
  {
    "name": "STD 6",
    "price": "8000,00"
  },
  {
    "name": "Citološki pregled urina",
    "price": "1600,00"
  },
  {
    "name": "ECHINOCOCCUS IgG At ELISA",
    "price": "1400,00"
  }
]