import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import { FACEBOOK_PIXEL_ID } from "./config";

// optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
const advancedMatching = { em: "some@email.com" };
const options = {
  autoConfig: true,
  debug: false
};
ReactPixel.init(FACEBOOK_PIXEL_ID, advancedMatching, options);
ReactPixel.pageView();

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
