export const alergijaNaLekove = [
  {
    "name": "Penicilin V",
    "price": "1800,00"
  },
  {
    "name": "Penicilin G",
    "price": "1800,00"
  },
  {
    "name": "Amoxicillin Ig E",
    "price": "1800,00"
  },
  {
    "name": "Ampicilin -C203",
    "price": "1800,00"
  },
  {
    "name": "Ciprofloxacin C108",
    "price": "1800,00"
  },
  {
    "name": "Adalimumab At",
    "price": "6600,00"
  }
]