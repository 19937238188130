import React from "react";

const Picture = props => {
  const { image } = props;
  return (
    <div
      style={{
        backgroundImage: `url(${image})`,
        width: "300px",
        height: "100%",
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat"
      }}
      className="picture"
    />
  );
};

export default Picture;
