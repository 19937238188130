export const genetika = [
  {
    name: "PHI test",
    price: "21,000.00"
  },
  {
    name: "Farmakogenetički profil",
    price: "120,000.00"
  },
  {
    name: "NeoBona prenatalni test",
    price: "77,000.00"
  },
  {
    name: "Kariotip iz plodove vode ( amniocenteza )",
    price: "18,000.00"
  },
  {
    name: "Kariotip iz limfocita krvi pupčanika (kordocenteza )",
    price: "12,000.00"
  },
  {
    name: "Kariotip iz horionskih čupica",
    price: "12,000.00"
  },
  {
    name: "Mikrodelecija Y hromozoma",
    price: "15,000.00"
  },
  {
    name: "Kariotip iz periferne krvi",
    price: "8,500.00"
  },
  {
    name: "Utvrđivanje pola iz horionskih resica,PCR",
    price: "11,000.00"
  },
  {
    name: "Faktor V ( Leiden V )-PCR ",
    price: "4,000.00"
  },
  {
    name: "Protrombin II - PCR",
    price: "4,000.00"
  },
  {
    name: "MTHFR-PCR",
    price: "4,000.00"
  },
  {
    name: "PAI 1-PCR ",
    price: "4,500.00"
  },
  {
    name: "Intolerancija na laktozu-PCR ",
    price: "7,500.00"
  },
  {
    name: "Intolerancija na gluten (HLA tipizacija -celijakija)",
    price: "9,700.00"
  },
  {
    name: "Screening za kolorektalni kancer -KRAS ",
    price: "4,800.00"
  },
  {
    name: "Sportski panel I",
    price: "15,000.00"
  },
  {
    name: "HLA-B27",
    price: "9,000.00"
  },
  {
    name: "K-Cistična fibroza",
    price: "15,400.00"
  },
  {
    name: "Hemohromatoza -PCR",
    price: "19,000.00"
  },
  {
    name: "Intolerancija na fruktozu",
    price: "9,000.00"
  },
  {
    name: "BRCA 1",
    price: "51,900.00"
  },
  {
    name: "BRCA 2 ",
    price: "67,500.00"
  },
  {
    name: "BRCA 1 i BRCA 2 parcijalno sekveniranje",
    price: "30,000.00"
  },
  {
    name: "K-Panorama Basic(prenatalni skrining)",
    price: "62,500.00"
  },
  {
    name: "Panorama Plus",
    price: "68,750.00"
  },
  {
    name: "Panorama Full panel ",
    price: "78,750.00"
  },
  {
    name: "K-HLA",
    price: "8,200.00"
  }
];
