export const saliva = [
  {
    name: "saliva-priprema uzorka",
    price: "100,00"
  },
  {
    name: "kalcijum - saliva",
    price: "120,00"
  },
  {
    name: "fosfor - saliva",
    price: "200,00"
  },
  {
    name: "Amilaza-saliva",
    price: "350,00"
  },
];
